define("labs-zap-search/helpers/validate-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateEmail = validateEmail;
  _exports.default = void 0;

  function validateEmail(email) {
    // eslint-disable-next-line no-useless-escape
    var tester = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
    if (!email) return false;
    if (email.length > 254) return false;
    var valid = tester.test(email);
    if (!valid) return false; // Further checking of some things regex can't handle

    var parts = email.split('@');
    if (parts[0].length > 64) return false;
    var domainParts = parts[1].split('.');
    if (domainParts.some(function (part) {
      return part.length > 63;
    })) return false;
    return true;
  }

  var _default = Ember.Helper.helper(validateEmail);

  _exports.default = _default;
});