define("labs-zap-search/templates/subscription-confirmation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pX0FUnMc",
    "block": "{\"symbols\":[\"boro\",\"district\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"cell\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"grid-container\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"grid-x grid-padding-x grid-padding-y align-middle align-center subscribers-confirm\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"cell large-8 padding-top-4\"],[8],[0,\"\\n        \"],[7,\"h1\",true],[10,\"class\",\"header-xxl dcp-orange\"],[8],[0,\"Thank You For Subscribing To ZAP Updates.\"],[9],[0,\"\\n        \"],[7,\"p\",true],[10,\"class\",\"lg\"],[8],[0,\"You are currently subscribed to:\"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"model\",\"citywide\"]]],null,{\"statements\":[[0,\"          \"],[7,\"p\",true],[10,\"class\",\"md\"],[8],[0,\"Citywide\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"each\",[[23,0,[\"model\",\"boroughs\"]]],null,{\"statements\":[[0,\"          \"],[7,\"p\",true],[10,\"class\",\"md\"],[8],[1,[23,1,[\"name\"]],false],[0,\":\"],[9],[0,\"\\n          \"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,1,[\"communityBoards\"]]],null,{\"statements\":[[0,\"              \"],[7,\"li\",true],[8],[0,\"Community District \"],[1,[23,2,[]],false],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"          \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[7,\"p\",true],[8],[0,\"To modify subscriptions, \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"subscription-update\",[23,0,[\"target\",\"currentRoute\",\"params\",\"id\"]]]],{\"statements\":[[0,\"click here\"]],\"parameters\":[]}],[0,\".\"],[9],[0,\"\\n        \"],[5,\"link-to\",[],[[\"@route\"],[\"show-geography\"]],{\"statements\":[[0,\"\\n          \"],[7,\"button\",true],[8],[0,\"\\n            Go to Home\\n          \"],[9],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-zap-search/templates/subscription-confirmation.hbs"
    }
  });

  _exports.default = _default;
});